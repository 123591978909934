import { RequestFieldBadge, RequestStepper, RequestTimeline, WorkDayPeople } from "@/components";
import { ClientStatuses, FieldClassifications, Roles } from "@/enums";
import { IField, IClient, ISnakeApplication, ISnakeApplicationEntity, IEntityRegion } from "@/models";
import { Vue, Component } from "vue-property-decorator";

@Component({
    name: "clientView",
    metaInfo: {
        title: "Client",
    },
    components: {
        RequestFieldBadge,
        RequestStepper,
        RequestTimeline,
        WorkDayPeople,
    },
})
export default class ClientView extends Vue {

    public loading = false;

    public clientStatus = false;

    public readMore = false;

    public clientId: string = this.$route.params.id;

    public onStatusChangeval(): void {
        if(this.client.status === ClientStatuses.Active) {
            this.changeStatus(ClientStatuses.Disabled);
        }
        else {
            if(this.client.status === ClientStatuses.Disabled){
                this.changeStatus(ClientStatuses.Active);
            }
        }
    }

    async changeStatus(status: ClientStatuses): Promise<void>{
        const message = status === ClientStatuses.Disabled ? "disable" : "enable";
        if (confirm(`Are you sure you want to ${message} client?`)) {
            try{
                await this.$store.dispatch("app/changeStatus", {
                    id: this.client.id,
                    status,
                    requestId: this.client.request.id,
                    by: this.$store.getters["account/user"].email,
                });
                this.clientStatus = status === ClientStatuses.Active;
                this.$emit("success", "Client updated succesfully");
            }
            catch(error: any) {
                if(error.response?.data.status === 403){
                    this.$emit("error", "Unauthorized to process this action");
                }
                else {
                    this.$emit("error", error.response?.data.title + " : " + error.response?.data.detail);
                }
            }
        }
        else {
            this.clientStatus = status === ClientStatuses.Disabled ? true : false;
        }
    }

    get canEditClient(): boolean {
        return this.$store.getters["account/user"].roles.includes(Roles.Administrator) || this.$store.getters["account/user"].roles.includes(Roles.AadAdmin);
    }

    get client(): IClient {
        return this.$store.getters["app/currentClient"];
    }

    get clientStatusLabel(): string {
        if(this.clientStatus){
            return "Active";
        }
        else {
            return "Disabled";
        }
    }

    get requestGreenFields(): IField[] {
        if(this.$store.getters["app/currentClient"] != null) {
            return this.$store.getters["app/currentClient"].fields.filter(f => f.classification === FieldClassifications.Green);
        } else {
            return null;
        }
    }

    get requestOrangeFields(): IField[] {
        if(this.$store.getters["app/currentClient"] != null) {
            return this.$store.getters["app/currentClient"].fields.filter(f => f.classification === FieldClassifications.Orange);
        } else {
            return null;
        }
    }

    get requestRedFields(): IField[] {
        if(this.$store.getters["app/currentClient"] != null) {
            return this.$store.getters["app/currentClient"].fields.filter(f => f.classification === FieldClassifications.Red);
        } else {
            return null;
        }
    }

    get requestNewFields(): IField[] {
        if(this.$store.getters["app/currentClient"] != null) {
            return this.$store.getters["app/currentClient"].fields.filter(f => f.classification === FieldClassifications.NewField);
        } else {
            return null;
        }
    }

    get snakeApp(): ISnakeApplication {
        return this.$store.getters["app/currentSnakeApp"];
    }

    get appEntities(): IEntityRegion[]{
        return this.$store.getters["app/snakeGroupedEntities"];
    }

    get reducedAppEntities(): IEntityRegion[]{
        const reduced: IEntityRegion[] = [];
        if(this.appEntities != null && this.appEntities.length !== 0){
            reduced.push({region: this.appEntities[0].region, entities: this.appEntities[0].entities.slice(0, 3)});
        }
        return reduced;
    }

    get showAllEntities(): boolean {
        const lengths = this.appEntities.map(ge => this.computeTotalLength(ge.entities));
        const totalLengths  = this.sum(lengths);
        return totalLengths < 90;
    }

    async mounted(): Promise<void> {
        this.loading = true;
        try {
            await this.$store.dispatch("app/loadClientById", this.clientId);
            this.clientStatus = this.$store.getters["app/currentClient"].status === ClientStatuses.Active;
            await this.$store.dispatch("app/loadSnakeAppById", this.$store.getters["app/currentClient"].snakeId);
            await this.$store.dispatch("app/loadSnakeGroupedEntities", this.snakeApp.general.entities);
        }
        catch (error) {
            this.$emit("error", error);
        }
        this.loading = false;
    }

    public snakeApplicationLogo(): string {
        const imageString = this.snakeApp.general.logo;
        if(imageString) {
            return imageString;
        }
        else {
            return require("@/assets/images/snake-v2-blue.svg");
        }
    }

    public successMessage(message: string): void {
        this.$emit("success", message);
    }

    public errorMessage(message: string): void {
        this.$emit("error", message);
    }

    public getEntitiesHtml(entities: ISnakeApplicationEntity[]): string {
        return entities?.map(e => e.name).join(", ");
    }

    private computeTotalLength(entities: ISnakeApplicationEntity[]): number{
        const lengths = entities.map(e => e.name.length);
        const totalLengths  = this.sum(lengths);
        return totalLengths;
    }

    private sum(lengths: number[]): number{
        return lengths.reduce((sum, current) => sum + current, 0);
    }
}
